import React from 'react'
import Page from '../../components/SparkleWallet/UserWallet'
import PageGradient from '../../components/PageGradient'

const UserWallet = () => {
    return <PageGradient>
        <Page />
    </PageGradient>
}

export default UserWallet