import React from 'react'
import Header from '../components/Header'
import UserBalance from './UserBalance'
import Divider from '@material-ui/core/Divider'
import RecentActivities from './RecentActivities'
import {navigate} from 'gatsby'
import './index.scss'

const UserWallet = () => {
    return <>
        <div className="user-wallet">
            <Header onClick={() => navigate('/')} text = "Sparkle Blessing"/>
            <UserBalance />
            <Divider />
            <RecentActivities />
        </div></>
}

export default UserWallet