import { useState, useEffect, useCallback } from 'react'
import { getUser } from '../../../../../services/auth'
import { getTransaction } from '../../../../../api/public/wallet'

export default function useGetTransactions() {
    const [transactions, setTransactions] = useState([])

    let getTransactions = useCallback(async () => {
        let user = getUser()

        let data = await getTransaction(user.userId, user.token)

        if (data && data.error) {
        } else {
            console.log(data)
            setTransactions(data)
        }

    }, [])

    useEffect(() => {
        let mounted = true

        if (mounted) {
            getTransactions()
        }
        return () => {
            mounted = false
        }
    }, [])
    return transactions
}