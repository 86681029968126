import React from 'react'
import './index.scss'

import ActivityIcon from './assets/activity_icon.png'

const Activity = ({transaction = 'vortex topup', amount = 0, symbol  = '-', date = 'date'}) => {
    return <div className='activity'>
        <img src = {ActivityIcon} className = 'activity__icon'/>
        <div className='activity__details'>
            <div className='activity__details-date'>{transaction}</div>   
            <div className='activity__details-type'>{date}</div>   
        </div> 
        <div className='activity__amount'>
            {symbol}{amount}
        </div>
    </div>
}

export default Activity