import React from 'react'
import './index.scss'

import Activity from './Activity'

import SeeMore from './SeeMore'

const RecentActivities = ({ activities = [] }) => {
    return <div className='recent-activities'>
        <span className='recent-activities__heading'>Recent activities</span>
        <div className='recent-activities__list'>
            {activities.length > 0 ? activities.map(({ title, amount, symbol, createdAt }) => <Activity transaction={title} amount={amount} symbol={symbol} date={createdAt} />) : "..."}
        </div>

    </div>

}

export default RecentActivities