import React from 'react'
import View from './view'

import useGetTransactions from './hooks/useGetTransactions'

const RecentActivities = () => {
    let transactions = useGetTransactions()

    return <View  activities =  {transactions}/>
}

export default RecentActivities