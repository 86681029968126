import React from 'react'
import './index.scss'
import { Link } from 'gatsby'
import SparkleMascot from '../../../../../assets/images/Mascot_Stand_Black_Stroke.webp'

const UserBalance = ({balance = 0}) => {
    return <div className='user-balance'>
        <div className='user-balance__card'>
            <img className='user-balance__image' src={SparkleMascot} />
            <div className='user-balance__text'>
                <span className='user-balance__info'>
                    Available balance
                </span>

                <div className='user-balance__amount'>
                    <div className='user-balance__currency'>
                        PHP
                    </div>
                    <div className='user-balance__number'>{balance}</div>
                </div>
            </div>
        </div>
        <Link to = '/user/topup' style = {{textDecoration: 'none'}}>
        <div className='user-balance__topup'><span className='user-balance__topup-button'>+ top-up</span></div>
        </Link>
    </div>
}

export default UserBalance